import { mapState } from 'vuex'

export const computed = {
  ...mapState('agendamentos', ['agendamento']),
  items() {
    return this.agendamento.logs_integracao
    /*const subtractDate = (date, x = 0) => {
      const sub_3_hours = 1000 * 60 * 60 * x
      date.setTime(date.getTime() - sub_3_hours)
      return date
    }
    return [
      {
        public_id: '1',
        description: 'Envio Integração 1',
        url: 'url.integracaoxyz.com.br',
        path: '/inserir/',
        query_params: '',
        method: 'POST',
        client_ip_address: '192.168.0.1',
        status_code: '200',
        execution_time: 3245,
        user: {
          email: 'leonardo.figueiredo@gruporotacerta.com.br',
        },
        created_at: subtractDate(new Date(), 9),
      },
      {
        public_id: '2',
        description: 'Entrada Buffer',
        url: 'url.integracaoxyz.com.br',
        path: '/entrada_buffer/',
        query_params: '',
        method: 'POST',
        client_ip_address: '192.168.0.1',
        status_code: '200',
        execution_time: 1241,
        user: {
          email: 'leonardo.figueiredo@gruporotacerta.com.br',
        },
        created_at: subtractDate(new Date(), 6),
      },
      {
        public_id: '3',
        description: 'Entrada Buffer',
        url: 'url.integracaoxyz.com.br',
        path: '/entrada_buffer/',
        query_params: '',
        method: 'POST',
        client_ip_address: '192.168.0.1',
        status_code: '200',
        execution_time: 1310,
        user: {
          email: 'leonardo.figueiredo@gruporotacerta.com.br',
        },
        created_at: subtractDate(new Date(), 3),
      },
    ]*/
  },
}
