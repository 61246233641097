<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="text-h5">Detalhe do Log</span>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="close"> Fechar </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                label="Nome"
                :value="item.name"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                label="Descricao"
                :value="item.description"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="5" md="3">
              <v-text-field
                label="Url"
                :value="item.url"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-text-field
                label="Acessado em"
                :value="item.created_at"
                readonly
              ></v-text-field> </v-col
            ><v-col cols="12" sm="2" md="1">
              <v-text-field
                label="method"
                :value="item.method"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="1">
              <v-text-field
                label="Status Code"
                :value="item.status_code"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="10">
              <v-text-field
                label="Path"
                :value="item.path"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="3" md="2">
              <v-text-field
                label="Tempo de Execução"
                :value="item.execution_time"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="10">
              <v-text-field
                label="Query Params"
                :value="item.query_params"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-textarea
                label="Headers"
                :value="item.headers"
                readonly
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-textarea label="Body" :value="item.body" readonly></v-textarea>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-textarea
                label="Response"
                :value="item.response"
                readonly
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                label="Observacao"
                :value="item.observacao"
                readonly
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row v-if="item.created_by">
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                label="Nome do Usuário"
                :value="item.created_by.name"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                label="Cpf do Usuário"
                :value="item.created_by.cpf"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                label="Celular do Usuário"
                :value="item.created_by.celular"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                label="Email do Usuário"
                :value="item.created_by.email"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'ShowLog',

  data() {
    return {}
  },
  props: {
    item: {
      typeof: Object,
      default: { created_by: {} },
    },
  },
  computed: {},

  watch: {},

  async created() {},

  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>
