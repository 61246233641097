<template>
  <v-card>
    <v-card-title>Solicitação de Baixas</v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <validation-observer ref="observer" v-slot="{ invalid }">
            <v-form>
              <validation-provider
                v-slot="{ errors }"
                :rules="{
                  required: true,
                }"
                name="terminal"
              >
                <v-select
                  v-model="form.terminal"
                  :items="terminais"
                  item-text="business_name"
                  item-value="public_id"
                  :error-messages="errors"
                  label="Terminal de Saída"
                  @change="$emit('update:terminalDestino', $event)"
                ></v-select>
              </validation-provider>
            </v-form>
            <v-card-actions class="justify-end">
              <v-btn color="error" :disabled="invalid" @click="callback"
                >Chamar ({{ quantidade }})</v-btn
              >
            </v-card-actions>
          </validation-observer>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ModalChamarCaminhoes',

  props: {
    callback: {
      type: Function,
      required: true,
    },
    quantidade: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      form: {
        terminal: '',
      },
    }
  },

  computed: {
    ...mapState('solicitacaoBaixa', ['terminais']),
  },

  methods: {
    resetar() {
      this.form.terminal = ''
    },
  },
}
</script>
