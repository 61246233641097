<template>
  <v-row>
    <v-col cols="12">
      <div>
        <div class="d-flex pa-2 justify-end">
          <v-btn type="button" color="primary" @click="fecharLogsIntegracoes"
            >Fechar</v-btn
          >
        </div>
        <v-data-table :items="items" :headers="headers">
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon title="Ver Log" small class="mx-1" @click="showLog(item)">
              mdi-eye
            </v-icon>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip :color="getColor(item)" text-color="white">{{
              item.status in status_text
                ? status_text[item.status]
                : status_text['0']
            }}</v-chip>
          </template>
          <template v-slot:[`item.valor`]="{ item }">
            {{ toReal(item.valor) }}
          </template>
        </v-data-table>
      </div>
      <v-dialog persistent hide-overlay v-model="show_log">
        <ShowLog :item="log" @close="close" />
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { data } from './_data'
import { computed } from './_computed'
import { filters } from './_filters'
import { methods } from './_methods'
import ShowLog from './Show.vue'
import { toReal } from '@/mixins/convertion'

export default {
  name: 'Logs Integracoes',
  inject: ['fecharLogsIntegracoes'],
  components: {
    ShowLog,
  },
  mixins: [toReal],
  data,
  computed,
  filters,
  methods,
}
</script>

<style></style>
