import { mapState } from 'vuex'

export const computed = {
  ...mapState('agendamentos', ['agendamento']),
  documentos() {
    const from_agendamento = this.agendamento.anexos
    let from_pedidos = []
    this.agendamento.pedidos_agendados.map((i) => {
      from_pedidos = from_pedidos.concat(i.documentos)
    })
    return [].concat(
      from_agendamento.map((i) => ({ ...i, from: 'Agendamento' })),
      from_pedidos.map((i) => ({ ...i, from: 'Pedido' }))
    )
  },
}
