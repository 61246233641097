<template>
  <v-container class="container-comprovante text-center text-subtitle-2">
    <v-row>
      <v-col cols="12" class="d-flex justify-end">
        <span class="text-caption">{{ agendamento.public_id }}</span>
      </v-col>
    </v-row>
    <div v-if="!showComprovante">
      <div class="d-flex pa-2">
        <v-btn type="button" color="primary" @click="fecharComprovanteServicos"
          >Fechar</v-btn
        >
      </div>
      <v-data-table
        :items="agendamento.servicos_triados"
        :headers="headersServicos"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small @click="selecionaServico(item)">mdi-eye</v-icon>
        </template>
      </v-data-table>
    </div>
    <div v-else>
      <v-row id="actions">
        <v-col class="d-flex pa-8">
          <v-btn
            type="button"
            color="primary"
            @click="voltarComprovanteServicos"
            >Voltar</v-btn
          >
          <v-spacer />
          <v-btn color="secondary" @click="imprimirComprovanteServicos"
            >Imprimir</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-comprovante">
          <p><strong>Comprovante de Serviço</strong></p>
          <p>
            <strong>{{ comprovante.servico.descricao }}</strong>
          </p>

          <p class="mt-2 aviso">Ao descer do veículo, uso obrigatório de</p>
          <p class="mt-2 aviso">calço na roda, enquanto no pátio.</p>
        </v-col>
      </v-row>
      <v-row class="row-gate">
        <v-col class="col-comprovante">
          <strong>{{
            agendamento.gate.business_name ||
            agendamento.gate.tenant ||
            defaultValue
          }}</strong>
          <p>
            CNPJ: <strong>{{ agendamento.gate.cnpj || defaultValue }}</strong>
          </p>
        </v-col>
      </v-row>

      <v-row class="row-cliente">
        <v-col class="col-comprovante">
          <p>Cliente:</p>
          <p>
            <strong>{{
              agendamento.cliente.business_name || defaultValue
            }}</strong>
          </p>
        </v-col>
      </v-row>
      <v-row class="row-cliente">
        <v-col class="col-comprovante">
          <p>Usuário:</p>
          <p>
            <strong>{{ agendamento.agendado_por || defaultValue }}</strong>
          </p>
        </v-col>
      </v-row>
      <v-row class="row-veiculo">
        <v-col class="col-comprovante">
          <p>
            Cavalo:
            <strong>{{
              agendamento.veiculo.placa_cavalo || defaultValue
            }}</strong>
          </p>
          <p>
            Modalidade:
            <strong>{{
              agendamento.veiculo.modalidade.descricao || defaultValue
            }}</strong>
          </p>
          <p>
            Carroceria:
            <strong>{{
              agendamento.veiculo.carroceria.descricao || defaultValue
            }}</strong>
          </p>
        </v-col>
      </v-row>
      <v-row class="row-motorista">
        <v-col class="col-comprovante">
          <p>Motorista:</p>
          <p>
            <strong>{{
              agendamento.motorista.user.name || defaultValue
            }}</strong>
          </p>
          <p>
            Cpf:
            <strong>{{
              agendamento.motorista.user.cpf || defaultValue
            }}</strong>
          </p>
          <p>
            Celular:
            <strong>{{
              agendamento.motorista.user.celular || defaultValue
            }}</strong>
          </p>
        </v-col>
      </v-row>
      <v-row class="row-horarios">
        <v-col class="col-comprovante">
          <div v-if="agendamento.data_cota">
            <p>Data da cota para Carga/Descarga:</p>
            <p>
              <strong>{{ agendamento.data_cota | iso2br }}</strong>
            </p>
          </div>
          <div v-if="agendamento.data_pedido">
            <p>Data da pedido para Carga/Descarga:</p>
            <p>
              <strong>{{ agendamento.data_pedido | date2BR }}</strong>
            </p>
          </div>
          <p>Data/Hora de chegada na Triagem:</p>
          <p>
            <strong>{{
              agendamento.triado_em || new Date() | toBrazilianDateTime
            }}</strong>
          </p>
          <p>Data/Hora de iniciação do serviço:</p>
          <p>
            <strong>{{
              comprovante.servico.iniciado_em | toBrazilianDateTime
            }}</strong>
          </p>
          <p>Data/Hora de finalização do serviço:</p>
          <p>
            <strong>{{
              comprovante.servico.finalizado_em | toBrazilianDateTime
            }}</strong>
          </p>
          <div v-if="agendamento.cancelado_em">
            <p>Data/Hora do Cancelamento:</p>
            <p>
              <strong>{{
                agendamento.cancelado_em | toBrazilianDateTime
              }}</strong>
            </p>
            <p>
              Motivo do Cancelamento:
              <strong>{{ agendamento.motivo_cancelamento }}</strong>
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row class="row-senhas">
        <v-col class="col-comprovante">
          <p><strong> Senha </strong></p>
          <p style="font-size: 30px">
            {{ getSenha(senha) || '-' }}
          </p>
        </v-col>
      </v-row>
      <v-row class="row-senhas">
        <v-col class="col-comprovante">
          <p><strong> Setor </strong></p>
          <p style="font-size: 30px">
            {{
              agendamento.vaga != null
                ? agendamento.vaga.setor.descricao.toUpperCase()
                : '-'
            }}
          </p>
          <p><strong> Vaga </strong></p>
          <p style="font-size: 30px">
            {{
              agendamento.vaga != null
                ? agendamento.vaga.descricao.toUpperCase()
                : '-'
            }}
          </p>
        </v-col>
      </v-row>

      <v-row class="row-cliente">
        <v-col class="col-comprovante">
          <div
            style="margin: 30px"
            v-for="(pedido, index) in agendamento.pedidos_agendados"
            :key="index"
          >
            <p>
              SubProduto:
              <strong>{{ pedido.pedido.subProduto.descricao }}</strong>
            </p>
            <p>
              Armazem:
              <strong>{{
                pedido.armazem != null
                  ? pedido.armazem.descricao.toUpperCase()
                  : '-'
              }}</strong>
            </p>
            <p>
              Quantidade:
              <strong>{{ converteKg(pedido.quantidade_agendada) }} KG</strong>
            </p>
            <p>
              N° NFE: <strong>{{ pedido.numero_nfe }}</strong>
            </p>
            <p>
              Embalagem:
              <strong>{{ pedido.pedido.embalagem.descricao }}</strong>
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-comprovante">
          <div style="margin: 30px">
            <p>
              Servico:
              <strong>{{ comprovante.servico.servico.descricao }}</strong>
            </p>
            <p>
              Forma Pagamento:
              <strong>{{
                comprovante.servico.forma_pagamento.descricao
              }}</strong>
            </p>
            <p>
              Valor:
              <strong>{{ 'R$ ' + getItemValor(comprovante.servico) }}</strong>
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="this.exibeClassificacao === true">
        <v-col class="col-comprovante">
          <div
            style="margin: 30px"
            v-for="(classificacao, index) in classificacoes"
            :key="index"
          >
            <p>
              Caracteristica:
              <strong>{{ classificacao.caracteristica }}</strong>
            </p>
            <p>
              Valor: <strong>{{ classificacao.valor }}</strong>
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-end">
          <span class="text-caption">{{ agendamento.public_id }}</span>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { toReal, date2br } from '@/mixins/convertion.js'
import { data } from './_data'
import { computed } from './_computed'
import { filters } from './_filters'
import { methods } from './_methods'
import classificacaoAPI from '@/services/classificacao'

export default {
  name: 'Comprovante',
  inject: ['fecharComprovanteServicos'],
  mixins: [toReal, date2br],
  data,
  computed,
  filters,
  async created() {
    try {
      const data = await classificacaoAPI.listClassificacoes(
        this.empresaAtual.public_id,
        {
          agendamento: this.agendamento.public_id,
        }
      )

      this.exibeClassificacao = this.agendamento.fornecedor.config.agendamento.exibirClassificacao

      this.classificacoes = data.results[0].classificacoes
    } catch (error) {
      console.log(error)
    }
  },
  methods,
}
</script>

<style>
.container-comprovante p {
  text-transform: uppercase;
  line-height: 5px;
}

.col-comprovante {
  padding: 1px;
}

.row-senhas strong {
  font-size: 20px;
  line-height: 20px;
}
strong {
  font-size: 15px;
  line-height: 15px;
}

p {
  font-size: 15px;
  line-height: 15px;
}
/* configuração para impressora térmica */
@media print {
  .v-application--wrap,
  .v-dialog button {
    display: none;
  }

  .v-dialog {
    display: block;
    height: 100vh;
    width: 100vw;
    margin: 0;
  }

  .v-card {
    height: 100%;
    width: 100%;
  }

  .v-data-table th,
  .v-data-table td {
    font-size: 10px !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .aviso {
    font-size: 10px;
  }

  @page {
    margin: 0;
  }
}
</style>
