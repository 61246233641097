export function data() {
  return {
    show_log: false,
    log: {},
    status_text: { 0: 'Indefinido', 1: 'Sucesso', 2: 'Erro' },
    status_color: { 0: 'blue', 1: 'green', 2: 'red' },
    headers: [
      { text: 'Ações', value: 'actions', habilited: true, block: true },
      {
        text: 'Public Id',
        align: 'start',
        value: 'public_id',
        habilited: true,
        block: false,
      },
      {
        text: 'Acessado em',
        value: 'created_at',
        habilited: true,
        block: true,
      },
      {
        text: 'Status',
        value: 'status',
        habilited: true,
        block: false,
      },
      { text: 'Nome', value: 'name' },
      { text: 'Valor', value: 'valor' },
      { text: 'Url', value: 'url', habilited: true, block: false },
      { text: 'Path', value: 'path', habilited: true, block: true },
      {
        text: 'Query Params',
        value: 'query_params',
        habilited: true,
        block: false,
      },
      { text: 'Method', value: 'method', habilited: true, block: false },
      {
        text: 'Status Code',
        value: 'status_code',
        habilited: true,
        block: false,
      },
      {
        text: 'Tempo de execução (ms)',
        value: 'execution_time',
        habilited: true,
        block: false,
      },
      {
        text: 'Usuario',
        value: 'created_by.email',
        habilited: true,
        block: false,
      },
    ],
  }
}
