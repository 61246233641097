var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"1080px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisa","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Fechar ")])],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.perfilAtual == _vm.Perfil.Transportadora
                  ? _vm.headers
                  : _vm.headers.filter(
                      function (item) { return item.type != _vm.Perfil.Transportadora; }
                    ),"items":_vm.programacoes.filter(
                  function (item) { return item.restante.qt_peso > 0 ||
                    item.restante.qt_caminhoes > 0; }
                ),"search":_vm.search},scopedSlots:_vm._u([{key:"item.data_inicio",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseDatetime(new Date(item.data_inicio)))+" ")]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")])]}}],null,true)})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.save}},[_vm._v("Salvar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }