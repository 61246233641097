<template>
  <v-container class="container-comprovante text-center text-subtitle-2">
    <v-row>
      <v-col cols="12" class="d-flex justify-end">
        <span class="text-caption">{{ agendamento.public_id }}</span>
      </v-col>
    </v-row>

    <v-row id="actions">
      <v-col class="d-flex pa-8">
        <v-btn type="button" color="primary" @click="fecharOrdemDeCarga"
          >Fechar</v-btn
        >
        <v-spacer />
        <v-btn color="secondary" @click="imprimirComprovante">Imprimir</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="border border-dark color-gray" cols="3">
        <v-img class="mx-auto" contain src="@/assets/images/logo_eleva.png" />
      </v-col>
      <v-col class="border border-top-dark" cols="6">
        <v-row>
          <v-col cols="6">
            <p class="text-start">
              <span>
                <b>Empresa: {{ agendamento.fornecedor.trade_name }}</b>
              </span>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <p class="text-start">
              <span>
                Cidade:
                <b>{{ agendamento.fornecedor.city }}</b>
              </span>
            </p>
          </v-col>
          <v-col cols="6">
            <p class="text-start">
              <span>
                Cpnj:
                <b>{{ agendamento.fornecedor.cnpj }}</b>
              </span>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <p class="text-start">
              <span>
                Endereço:
                <b>{{ agendamento.fornecedor.district }}</b>
              </span>
            </p>
          </v-col>
          <v-col cols="6">
            <p class="text-start">
              <span>
                Nro.:
                <b>{{ agendamento.fornecedor.numero }}</b>
              </span>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <p class="text-start">
              <span>
                Complemento:
                <b></b>
              </span>
            </p>
          </v-col>
          <v-col cols="6">
            <p class="text-start">
              <span>
                Bairro:
                <b>{{ agendamento.fornecedor.district }}</b>
              </span>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <p class="text-start">
              <span>
                UF:
                <b>{{ agendamento.fornecedor.state }}</b>
              </span>
            </p>
          </v-col>
          <v-col cols="6">
            <p class="text-start">
              <span>
                CEP:
                <b>{{ agendamento.fornecedor.zip_code }}</b>
              </span>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <p class="text-start"><b>R.Mapa: SC 001177-0</b></p>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="border border-dark" cols="3">
        <v-row class="border border-dark">
          <v-col cols="12">
            <h2 class="mb-0"><b>NR.Carregamento</b></h2>
          </v-col>
        </v-row>
        <v-row class="border border-dark">
          <v-col cols="12">
            <h2 class="mb-0"><b>0000-000000</b></h2>
          </v-col>
        </v-row>
        <v-row class="border border-dark">
          <v-col cols="12">
            <h2 class="mb-0"><b>NR Pedido/Frete:</b></h2>
          </v-col>
        </v-row>
        <v-row class="border border-dark">
          <v-col cols="12">
            <h2 class="mb-0">
              <b
                >{{ agendamento.pedidos_agendados[0].pedido.numero_pedido }} ({{
                  agendamento.tipo_frete.descricao
                }})</b
              >
            </h2>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="border border-top-dark">
      <v-col cols="4">
        <p class="text-center">
          <span>
            Data Emissão:
            <b>{{ agendamento.agendado_em | toBrazilianDateTime }}</b>
          </span>
        </p>
      </v-col>
      <v-col cols="4">
        <p class="text-center">
          <span>
            Responsável:
            <strong>{{ agendamento.agendado_por }}</strong>
          </span>
        </p>
      </v-col>
      <v-col cols="4">
        <p class="text-center">
          <span>
            E-mail:
            <strong>logistica@elevaquimica.com.br</strong>
          </span>
        </p>
      </v-col>
    </v-row>
    <v-row class="border border-top-dark">
      <v-col
        style="background-color: #989898"
        class="border border-top-dark"
        cols="12"
      >
        <h2 class="mb-0"><b>DADOS DO CLIENTE </b></h2>
      </v-col>
      <v-col cols="12">
        <p class="text-start">
          <b>Cliente: </b>{{ agendamento.cliente.trade_name }}
        </p>
      </v-col>
      <v-col cols="9">
        <p class="text-start"><b>Cidade: </b>{{ agendamento.cliente.city }}</p>
      </v-col>
      <v-col cols="3">
        <p class="text-start"><b>UF </b>{{ agendamento.cliente.state }}</p>
      </v-col>
    </v-row>
    <v-row class="border border-top-dark">
      <v-col
        style="background-color: #989898"
        class="border border-top-dark"
        cols="12"
      >
        <h2 class="mb-0"><b>DADOS DO TRANSPORTE</b></h2>
      </v-col>
      <v-col cols="9">
        <p class="text-start">
          <b>Placa: </b>{{ agendamento.veiculo.placa_cavalo || defaultValue }}
        </p>
      </v-col>
      <v-col cols="3">
        <p class="text-start">
          <b>CNPJ: </b>{{ agendamento.transportadora.cnpj || defaultValue }}
        </p>
      </v-col>
      <v-col cols="12">
        <p class="text-start">
          <b>Transpotadora: </b
          >{{ agendamento.transportadora.trade_name || defaultValue }}
        </p>
      </v-col>
      <v-col cols="12">
        <p class="text-start">
          <b>Motorista: </b
          >{{ agendamento.motorista.user.name || defaultValue }}
        </p>
      </v-col>
      <v-col cols="12">
        <p class="text-start">
          <b>CPF/RG: </b>{{ agendamento.motorista.user.cpf || defaultValue }}
        </p>
      </v-col>
    </v-row>
    <v-row class="border border-top-dark">
      <v-col
        style="background-color: #989898"
        class="border border-top-dark"
        cols="12"
      >
        <h2 class="mb-0"><b>DADOS DO PRODUTO</b></h2>
      </v-col>
    </v-row>
    <v-row class="border border-top-dark">
      <v-col class="border border-top-dark" cols="3">
        <p class="text-start"><b>Código</b></p>
      </v-col>
      <v-col class="border border-top-dark" cols="9">
        <p class="text-start"><b>Descrição do Produto</b></p>
      </v-col>
    </v-row>
    <span
      class="border border-top-dark"
      v-for="(item, index) in agendamento.pedidos_agendados"
      :key="index"
    >
      <v-row class="border border-top-dark">
        <v-col class="border border-top-dark" cols="3">
          <p class="text-start">40088</p>
        </v-col>
        <v-col class="border border-top-dark" cols="9">
          <p class="text-start">{{ item.pedido.subProduto.descricao }}</p>
        </v-col>
      </v-row>
      <v-row style="background-color: #989898" class="border border-top-dark">
        <v-col class="border border-top-dark" cols="1">
          <p class="text-start"><b>Cód. MP</b></p>
        </v-col>
        <v-col class="border border-top-dark" cols="6">
          <p class="text-start"><b>Matéria Prima</b></p>
        </v-col>
        <v-col class="border border-top-dark" cols="2">
          <p class="text-start"><b>Quantidade</b></p>
        </v-col>
        <v-col class="border border-top-dark" cols="1">
          <p class="text-start"><b>UM</b></p>
        </v-col>
        <v-col class="border border-top-dark" cols="2">
          <p class="text-start"><b>Número Lote</b></p>
        </v-col>
      </v-row>
      <v-row
        class="border border-top-dark"
        v-for="(itemComp, index) in item.pedido.subProduto.composicao"
        :key="index"
      >
        <v-col class="border border-top-dark" cols="1">
          <p class="text-start">
            <b>{{ itemComp.codigo_mp }}</b>
          </p>
        </v-col>
        <v-col class="border border-top-dark" cols="6">
          <p class="text-start" style="font-size: 10px">
            <b>{{ itemComp.materia_prima }}</b>
          </p>
        </v-col>
        <v-col class="border border-top-dark" cols="2">
          <p class="text-start">
            <b>{{ itemComp.quantidade }}</b>
          </p>
        </v-col>
        <v-col class="border border-top-dark" cols="1">
          <p class="text-start">
            <b>{{ itemComp.unidade_medida }}</b>
          </p>
        </v-col>
        <v-col class="border border-top-dark" cols="2">
          <p class="text-start"><b>Número Lote</b></p>
        </v-col>
      </v-row>
    </span>
    <v-row>
      <v-col cols="12">
        <h2 class="mb-0">
          <b
            >ORDEM DE CARREGAMENTO VÁLIDA POR 48 HORAS A PARTIR DA DATA DE
            EMISSÃO</b
          >
        </h2>
      </v-col>
    </v-row>
    <v-divider class="my-4"></v-divider>
    <v-row>
      <v-col cols="12" class="d-flex justify-end">
        <span class="text-caption">{{ agendamento.public_id }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { toReal, date2br } from '@/mixins/convertion.js'
import {
  cota_dia_todo,
  cota_mesmo_dia,
  mostra_cota_mesmo_dia,
} from '@/mixins/cotas.js'

import { computed } from './_computed'
import { filters } from './_filters'
import { methods } from './_methods'

export default {
  name: 'Comprovante',
  inject: {
    fecharOrdemDeCarga: {
      from: 'fecharOrdemDeCarga',
    },
    showShortcurt: {
      from: 'showShortcurt',
      default: false,
    },
  },
  mixins: [
    toReal,
    date2br,
    cota_dia_todo,
    cota_mesmo_dia,
    mostra_cota_mesmo_dia,
  ],
  computed,
  filters,
  methods,
}
</script>

<style>
table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.container-comprovante p {
  text-transform: uppercase;
  line-height: 5px;
}

strong::before {
  content: ' ';
}

.label::after {
  content: ':';
}

@media print {
  .v-dialog_content.v-dialog_content--active {
    position: inherit;
  }

  .v-dialog--fullscreen {
    position: absolute;
    overflow: visible;
  }

  #actions {
    display: none;
    height: 0;
  }

  #logos {
    display: none;
  }

  @page {
    margin: 0;
  }
}
</style>
