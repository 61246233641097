export const filters = {
  toBrazilianDateTime(date) {
    if (!date) return 'Não Definida'
    return new Date(date).toLocaleString('pt-br')
  },
  toBrazilianDateTimeAdded(date) {
    if (!date) return 'Não Definida'
    const data = new Date(date)
    const add_3_hours = 1000 * 60 * 60 * 3
    data.setTime(data.getTime() + add_3_hours)
    return data.toLocaleString('pt-br')
  },
  iso2br(date) {
    if (!date) return 'Não Definida'
    // Remove Z
    let data_splited = date.split('Z')[0]
    // Split DATE and TIME
    data_splited = data_splited.split('T')
    const data = data_splited[0].split('-')
    console.log(data)
    if (data.length != 3) return 'Não Definida'
    return `${data[2]}/${data[1]}/${data[0]}`
  },
}
