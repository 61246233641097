<template>
  <v-row class="my-8">
    <v-col cols="12" md="12">
      <v-card v-if="canList">
        <v-row class="ma-0">
          <v-col cols="12" md="12">
            <div class="d-flex justify-space-between align-center mx-8">
              <v-card-title>Dashboard</v-card-title>
              <div>
                <v-autocomplete
                  v-if="showSelectGate"
                  @change="handleGateSelecionado"
                  v-model="gateSelecionado"
                  label="Gate"
                  prepend-icon="mdi-domain"
                  :items="gates"
                  :item-text="(item) => `${item.business_name} - ${item.cnpj}`"
                  item-value="public_id"
                />
              </div>
              <div>
                <v-btn
                  color="primary"
                  class="ml-2"
                  :disabled="disableButtonFiltro"
                  @click="
                    () => {
                      this.showDashboard = 1
                      toggleFilters()
                    }
                  "
                  >Filtros</v-btn
                >
              </div>
            </div>
          </v-col>

          <v-col cols="12" md="12">
            <v-row
              class="flex-nowrap overflow-auto"
              :class="{
                'justify-center': Object.keys(dashboardFilters).length <= 6,
              }"
            >
              <v-col
                cols="2"
                md="2"
                v-for="([servicoID, etapas], index) of Object.entries(
                  dashboardFilters
                )"
                :key="index"
                class="d-flex justify-center"
              >
                <dashboard-item
                  ref="dashboardItem"
                  v-show="tudoPopulado"
                  class="dashboard__item"
                  :index="index"
                  :servicoID="servicoID"
                  :etapas="etapas"
                  :limit="999"
                  :gate="gateSelecionado"
                  @search="search"
                />
              </v-col>
            </v-row>
            <v-overlay :value="!tudoPopulado">
              <v-progress-circular indeterminate size="64" />
            </v-overlay>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-dialog v-model="showFilters" :max-width="500">
      <dashboard-filter @save="handleFilter" />
    </v-dialog>
  </v-row>
</template>

<script>
import DashboardItem from './DashboardItem.vue'
import DashboardFilter from './DashboardFilter.vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import { BasicPermissions, Sequences } from '@/utils/permissions'
import ContratoAPI from '@/services/tenants/contratos'
import { preferencias } from '@/mixins/preferencias'

export default {
  name: 'Dashboard',

  components: { DashboardItem, DashboardFilter },
  mixins: [preferencias],

  data() {
    return {
      showDashboard: false,
      showFilters: false,
      showSelectGate: false,
      disableButtonFiltro: false,
      intervals: [],
      gates: [],
      gateSelecionado: null,
      tudoPopulado: false,
      intervaloDeAtualizacaoEmSegundos: 30,
    }
  },

  computed: {
    ...mapState('patio', ['dashboardFilters']),
    ...mapState('auth', ['empresaAtual']),
    ...mapState('gerenciamentoServico', ['params']),

    permission() {
      return Sequences.GerenciamentoServico.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
  },

  methods: {
    ...mapMutations('patio', ['SET_DASHBOARD_FILTERS']),
    ...mapMutations('gerenciamentoServico', ['UPDATE_PARAMS']),
    ...mapActions('patio', ['getServicos']),
    search() {
      this.$emit('search')
    },

    async handleGateSelecionado() {
      this.disableButtonFiltro = false

      let params = this.params
      params['gate'] = this.gateSelecionado
      this.UPDATE_PARAMS(params)
      this.handleFilter([])
      await this.getServicos({
        proprietario: this.empresaAtual.public_id,
        gate: this.gateSelecionado,
      })
      if (this.preferencias) {
        this.preferencias.gate = this.gateSelecionado
      }
    },

    async toggleFilters() {
      this.showFilters = !this.showFilters
    },

    handleFilter(filterItems) {
      if (this.empresaAtual.gate) {
        localStorage.setItem(
          'DashboardGerenciamentoServico' + this.empresaAtual.public_id,
          JSON.stringify(filterItems)
        )
      } else {
        if (this.preferencias?.gate) {
          if (this.preferencias.gate?.public_id) {
            localStorage.setItem(
              'DashboardGerenciamentoServico' +
                this.preferencias.gate.public_id,
              JSON.stringify(filterItems)
            )
          }
        }
      }

      this.agruparEtapaPorServico(filterItems)

      this.$nextTick(async () => {
        const { dashboardItem } = this.$refs

        if (dashboardItem && dashboardItem.length) {
          await this.getTodosLabels(dashboardItem)
        }

        this.showFilters = false
        await this.updateDashboard()
      })
    },

    agruparEtapaPorServico(filterItems) {
      const groupedFilters = {}

      for (let i of filterItems) {
        if (i.etapas) {
          groupedFilters[i.id] = []
        } else {
          if (!groupedFilters[i.servico.id]) {
            groupedFilters[i.servico.id] = []
          }

          groupedFilters[i.servico.id].push(i)
        }
      }

      this.SET_DASHBOARD_FILTERS(groupedFilters)
    },

    removeIntervals() {
      if (this.intervals.length) {
        this.intervals.forEach((t) => clearInterval(t))
        this.intervals.splice(0)
      }
    },

    async updateDashboard() {
      const { dashboardItem } = this.$refs

      if (dashboardItem && dashboardItem.length) {
        try {
          await this.getTodosContadores(dashboardItem)
        } catch (error) {
          console.log(error)
        } finally {
          this.tudoPopulado = true
        }
      } else {
        this.tudoPopulado = true
      }
    },

    async getTodosContadores(dashboardItem) {
      this.tudoPopulado = false

      await Promise.all(dashboardItem.map((el) => el.getContadores()))

      this.tudoPopulado = true
    },

    async getTodosLabels(dashboardItem) {
      this.tudoPopulado = false

      await Promise.all(dashboardItem.map((el) => el.getServicoLabel()))

      this.tudoPopulado = true
    },
  },

  async created() {
    if (this.empresaAtual.gate) {
      await this.getServicos({ proprietario: this.empresaAtual.public_id })
      this.showSelectGate = false
      this.disableButtonFiltro = false
      this.tudoPopulado = true
      this.handleFilter(
        JSON.parse(
          localStorage.getItem(
            'DashboardGerenciamentoServico' + this.empresaAtual.public_id
          )
        )
      )
    } else {
      const { possible_gates } = await ContratoAPI.getPossiveisGates(
        this.empresaAtual.public_id
      )
      this.gates = possible_gates
      this.tudoPopulado = true
      this.showSelectGate = true
      this.disableButtonFiltro = true
      if (this.preferencias?.gate) {
        if (this.preferencias.gate?.public_id) {
          this.gateSelecionado = this.preferencias.gate.public_id
          this.handleFilter(
            JSON.parse(
              localStorage.getItem(
                'DashboardGerenciamentoServico' +
                  this.preferencias.gate.public_id
              )
            )
          )
        } else {
          this.gateSelecionado = this.preferencias.gate
        }
        if (this.gateSelecionado) {
          this.handleGateSelecionado()
        }
      }
    }
  },

  beforeDestroy() {
    this.removeIntervals()
  },
}
</script>

<style>
.dashboard__item {
  height: 100%;
  width: 100%;
}
</style>
