import { mapState } from 'vuex'

export const computed = {
  ...mapState('auth', ['empresaAtual']),
  ...mapState('agendamentos', ['agendamento']),
  class_agendamento() {
    if (this.agendamento.cancelado_em) {
      return 'red--text'
    }
    return
  },
}
