import { mapActions } from 'vuex'

import { TipoControladorSenha } from '@/utils/controladores'

export const methods = {
  ...mapActions('agendamentos', [
    'gerarSenhasDaTriagem',
    'updateAtributoAgendamento',
  ]),
  ...mapActions('template', ['errorMessage']),

  async imprimirComprovante() {
    await this.gerarSenhas()
    setTimeout(window.print, 500)
  },

  getItemValor(item) {
    return (
      item.valorTotal || item.valor_faturamento || item.valor_movimentacao || 0
    )
  },

  getFormaPagamentoNameById(id) {
    return (
      Object.values(this.FormaPagamento).find((f) => f.id == id)?.name ||
      'Tipo Inválido'
    )
  },

  prepareSubProdutos() {
    const { pedidos_agendados } = this.agendamento

    return pedidos_agendados.map((p) => ({
      id: p.pedido.subProduto.id,
      tipo: TipoControladorSenha.SubProduto.id,
    }))
  },

  converteKg(item) {
    return (parseFloat(item) * 1000).toFixed(0)
  },

  prepareServicos() {
    const { servicos_triados } = this.agendamento

    return servicos_triados.map((s) => ({
      id: s.servico.id,
      descricao: s.servico.descricao,
      tipo: TipoControladorSenha.Servico.id,
    }))
  },

  prepareData() {
    const { fornecedor, tipo_operacao } = this.agendamento
    const subProdutos = this.prepareSubProdutos()
    const servicos = this.prepareServicos()
    let data = []
    if (servicos.find((item) => item.descricao == 'TRIAGEM')) {
      data = data
        .concat(
          subProdutos[0],
          servicos.filter((item) => item.descricao !== 'TRIAGEM')
        )
        .map((i) => ({
          utilizador: fornecedor.fornecedor_id,
          objeto_id: i.id,
          tipo_operacao: tipo_operacao.id,
          tipo: i.tipo,
        }))
    } else {
      data = data.concat(servicos).map((i) => ({
        utilizador: fornecedor.fornecedor_id,
        objeto_id: i.id,
        tipo_operacao: tipo_operacao.id,
        tipo: i.tipo,
      }))
    }
    return data
  },

  getSenhaPrincipal(senhas, subProduto) {
    return senhas.find(
      (s) => s.tipo == subProduto.tipo && s.objeto_id == subProduto.objeto_id
    ).sequencia
  },

  async gerarSenhas() {
    if (
      !this.senha &&
      !this.agendamento.senhas?.length &&
      !this.agendamento.triado
    ) {
      const data = this.prepareData()
      try {
        const senhas = await this.gerarSenhasDaTriagem({
          agendamento: this.agendamento,
          dados: data,
        })
        this.updateAtributoAgendamento({
          valor: [].concat(this.agendamento.senhas, senhas),
          nomeAtributo: 'senhas',
        })
        if (senhas.length > 0) {
          this.senha = this.getSenhaPrincipal(senhas, data[0])
        } else {
          this.errorMessage(
            'Nenhum controlador encontrado. Necessário cadastrar para permitir a geração de senha.'
          )
        }
      } catch (e) {
        if (e.response.status === 400) {
          this.errorMessage('Um ou mais controladores não foram encontrados')
        }
      }
    }
  },
}
