export function data() {
  return {
    defaultValue: "Informação não encontrada",
    headersPedidos: [
      { text: 'N° Pedido', value: 'pedido.numero_pedido' },
      { text: 'SubProduto', value: 'pedido.subProduto.descricao' },
      { text: 'Quantidade', value: 'quantidade_agendada' },
      { text: 'Embalagem', value: 'pedido.embalagem.descricao' },
    ],
    headersLaudo: [
      { text: 'Descrição', value: 'descricao' },
      { text: 'Sim', value: 'sim' },
      { text: 'Não', value: 'nao' },
      { text: 'Observação', value: 'observacao' },
    ],
  }
}
