<template>
  <v-container class="container-comprovante text-center text-subtitle-2">
    <div>
      <div class="d-flex pa-2">
        <v-btn type="button" color="primary" @click="fecharDocumentos"
          >Fechar</v-btn
        >
      </div>
      <v-data-table :items="documentos" :headers="headersDocumentos">
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small @click="open(item)">mdi-eye</v-icon>
        </template>
        <template v-slot:[`item.uploaded_on`]="{ item }">
          {{ item.uploaded_on | toBrazilianDateTime }}
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import { data } from './_data'
import { computed } from './_computed'
import { filters } from './_filters'
import { methods } from './_methods'

export default {
  name: 'Documentos',
  inject: ['fecharDocumentos'],
  data,
  computed,
  filters,
  methods,
}
</script>

<style></style>
