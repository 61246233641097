<template>
  <v-dialog persistent v-model="dialog" max-width="1080px">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ title }}</span>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisa"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="$emit('close')"> Fechar </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-data-table
                :headers="
                  perfilAtual == Perfil.Transportadora
                    ? headers
                    : headers.filter(
                        (item) => item.type != Perfil.Transportadora
                      )
                "
                :items="
                  programacoes.filter(
                    (item) =>
                      item.restante.qt_peso > 0 ||
                      item.restante.qt_caminhoes > 0
                  )
                "
                :search="search"
              >
                <template v-slot:[`item.data_inicio`]="{ item }">
                  {{ parseDatetime(new Date(item.data_inicio)) }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)"
                    >mdi-pencil</v-icon
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="save">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { parseDatetime } from '@/mixins/convertion.js'
import patioApi from '@/services/patio'
import Perfil from '@/utils/perfil'
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    agendamento: {
      type: Object,
      required: true,
    },
  },
  mixins: [parseDatetime],
  data() {
    return {
      title: 'Trocar Cota',
      loading: false,
      headers: [
        { text: 'Ações', value: 'actions', sortable: false },
        { text: 'Data da cota', value: 'data_inicio' },
        {
          text: 'Fornecedor',
          value: 'fornecedor',
        },
        { text: 'Terminal', value: 'terminal' },
        { text: 'Gate', value: 'proprietario' },
        { text: 'Tipo de Operação', value: 'tipo_operacao' },
        { text: 'Produto', value: 'produto' },
        { text: 'Subprodudo', value: 'sub_produto' },
        { text: 'Região', value: 'regiao' },
        { text: 'Tipos de frete', value: 'tipos_frete' },
        { text: 'Cliente', value: 'cliente' },
        { text: 'Pedidos', value: 'pedidos' },
        {
          text: 'Transporadora',
          value: 'transportadora',
          type: Perfil.Transportadora,
        },
        { text: 'Peso restante', value: 'restante.qt_peso' },
        { text: 'Caminhões restante', value: 'restante.qt_caminhoes' },
      ],
      search: '',
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual', 'perfilAtual']),
    ...mapState('patio', ['programacoesCliente', 'programacoesRegiao']),
    programacoes() {
      if (this.perfilAtual == Perfil.Transportadora) {
        return this.programacoesCliente
      }
      if (this.perfilAtual == Perfil.Cliente) {
        return this.programacoesRegiao
      }
      return []
    },
  },
  methods: {
    ...mapActions('patio', [
      'getProgramacoesClienteAgendavel',
      'getProgramacoesRegiaoAgendavel',
    ]),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    async save() {},
    async editItem(item) {
      try {
        if (this.perfilAtual == Perfil.Transportadora) {
          this.agendamento.programacao_cliente = item.public_id
        }
        if (this.perfilAtual == Perfil.Cliente) {
          this.agendamento.programacao_cliente = undefined
          this.agendamento.programacao_regiao = item.public_id
        }
        const agendamento = await patioApi.trocarCotaAgendamento({
          ...this.agendamento,
          proprietario: this.agendamento.detalhes.proprietario,
        })
        this.agendamento.data_cota = agendamento.data_cota
        this.successMessage(
          `Cota trocada para o dia ${this.parseDatetime(
            new Date(agendamento.data_cota.data_inicio)
          )} com sucesso! `
        )
      } catch (error) {
        if (error.response) {
          this.errorMessage(error.response.data)
        } else {
          this.errorMessage('Erro ao trocar cota!')
        }
      }
    },
  },
  async created() {
    this.Perfil = Perfil
    this.loading = true
    if (this.perfilAtual == Perfil.Transportadora) {
      await this.getProgramacoesClienteAgendavel({
        origem: this.empresaAtual,
        agendavel: true,
        unidade: this.agendamento.detalhes.gate.public_id,
      })
    }
    if (this.perfilAtual == Perfil.Cliente) {
      const t = { ...this.agendamento }
      await this.getProgramacoesRegiaoAgendavel({
        teste: t,
        origem: this.empresaAtual,
        agendavel: true,
        unidade: this.agendamento.detalhes.gate.public_id,
      })
    }
    this.loading = false
  },
}
</script>
