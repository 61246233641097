import { mapGetters, mapState } from 'vuex'

import { LOCAL_STORAGE_ID } from '@/utils/gerenciamentoServico'

export const computed = {
  ...mapState('auth', ['user', 'empresaAtual']),
  ...mapState('fornecedores', ['fornecedores']),
  ...mapState('clientes', ['clientes']),
  ...mapState('transportadoras', ['transportadoras']),
  ...mapGetters('contratos', ['terminais']),
  ...mapState('patio', ['regioes', 'representantes']),
  ...mapState('motoristas', ['motoristas']),

  showDataAgendamento() {
    return (
      this.params.data_agendamento_inicio?.visible ||
      this.params.data_agendamento_fim?.visible
    )
  },
  showDataTriagem() {
    return (
      this.params.data_triagem_inicio?.visible ||
      this.params.data_triagem_fim?.visible
    )
  },
  showDataCota() {
    return (
      this.params.data_cota_inicio?.visible ||
      this.params.data_cota_fim?.visible
    )
  },
  showDataPedido() {
    return (
      this.params.data_pedido_inicio?.visible ||
      this.params.data_pedido_fim?.visible
    )
  },
  showDataSaidaPatio() {
    return (
      this.params.data_saida_patio_inicio?.visible ||
      this.params.data_saida_patio_fim?.visible
    )
  },
  showDataConclusao() {
    return (
      this.params.data_conclusao_inicio?.visible ||
      this.params.data_conclusao_fim?.visible
    )
  },
  showFornecedor() {
    return (
      this.params.fornecedor.visible ||
      this.params.produto.visible ||
      this.params.sub_produto.visible
    )
  },
  showContratos() {
    return (
      this.params.terminal.visible ||
      this.params.cliente.visible ||
      this.params.transportadora.visible
    )
  },
  showRegiao() {
    return this.params.regiao.visible || this.params.representante.visible
  },
  showVeiculo() {
    return (
      this.params.motorista.visible ||
      this.params.placa_cavalo.visible ||
      this.params.placa_carreta_1.visible ||
      this.params.placa_carreta_2.visible ||
      this.params.eixo.visible
    )
  },
  showPublicId() {
    return (
      this.params.agendamento__public_id?.visible ||
      this.params.motorista__public_id?.visible ||
      this.params.chave_nfe?.visible ||
      this.params.veiculo__public_id?.visible
    )
  },
  areSettingsEmpty() {
    return !(
      this.showDataAgendamento ||
      this.showDataTriagem ||
      this.showDataCota ||
      this.showDataPedido ||
      this.showDataSaidaPatio ||
      this.showDataConclusao ||
      this.showFornecedor ||
      this.showContratos ||
      this.showRegiao ||
      this.showPublicId ||
      this.showVeiculo
    )
  },
  selectedOptions() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_ID)) || []
  },
  selectedFornecedor() {
    return this.params.fornecedor.value
  },
  selectedProduto() {
    return this.params.produto.value
  },
}
