import { mapActions } from 'vuex'
import TipoAgendamento from '@/utils/agendamento'
export const methods = {
  async imprimirComprovante() {
    setTimeout(window.print, 500)
  },
  ...mapActions('patio', ['getAgendamentoGerenciamentoServico']),
  ...mapActions('template', ['errorMessage']),
  async handleGerarTriagem(item) {
    try {
      const agendamento = await this.getAgendamentoGerenciamentoServico({
        public_id: item.public_id,
      })
      agendamento.isEdicao = false
      agendamento.isEdicaoTriagem = false
      this.$router.push({
        name: 'NovaTriagem',
        params: {
          agendamentoSelecionado: agendamento,
          tipoAgendamento: TipoAgendamento.Triagem,
        },
      })
    } catch (error) {
      console.log(error)
      this.errorMessage('Agendamento não encontrado!')
    }
  },
}
