<template>
  <div>
    <v-badge
      class="dashboard__badge"
      :offset-x="quantidadePorServico > limit / 10 ? 40 : 25"
      offset-y="20"
      color="white"
    >
      <template #badge>
        <span :style="{ color: getColor() }">{{
          quantidadePorServico > limit ? limit + '+' : quantidadePorServico
        }}</span>
      </template>
      <v-sheet
        class="dashboard__sheet black--text d-flex justify-center"
        rounded="xl"
        outlined
        :class="{ 'align-center': !etapas.length }"
        :style="{ border: `3px solid ${getColor()}` }"
      >
        <div class="d-flex flex-column flex-grow-1 pa-4">
          <h3
            v-if="!etapas.length"
            class="text-center"
            :style="{ color: getColor(), cursor: 'pointer' }"
            v-on:click="setServico(servicoID)"
          >
            {{ nomeServico | upper }}
          </h3>
          <p
            v-else
            class="text-center"
            :style="{ color: getColor(), cursor: 'pointer' }"
            v-on:click="setServico(servicoID)"
          >
            {{ nomeServico | upper }}
          </p>
          <div
            v-for="(etapa, index) in etapaComContadores"
            :key="index"
            class="d-flex align-center py-2 px-4"
            :style="{ cursor: 'pointer' }"
            v-on:click="setEtapa(etapa)"
          >
            <p class="mb-0 flex-grow-1">{{ etapa.descricao }}</p>
            <v-chip small color="primary">
              {{
                etapa.quantidadePorEtapa > limit
                  ? limit + '+'
                  : etapa.quantidadePorEtapa
              }}
            </v-chip>
          </div>
        </div>
      </v-sheet>
    </v-badge>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import PatioApi from '@/services/patio'
import { mapMutations, mapState } from 'vuex'

export default {
  props: {
    index: Number,
    limit: Number,
    servicoID: String,
    gate: String,
    etapas: {
      type: Array,
    },
  },
  filters: {
    upper(value) {
      return value.toUpperCase()
    },
  },

  data() {
    return {
      nomeServico: '',
      etapaComContadores: [],
      quantidadePorServico: 0,
      colors: {
        0: 'red',
        1: 'green',
        2: 'blue',
        3: 'orange',
        4: 'purple',
        5: 'brown',
        6: 'pink',
        7: 'grey',
        8: 'indigo',
        9: 'teal',
      },
      defaultColor: 'black',
      allowedStatus: ['AGENDADO'],
    }
  },

  computed: {
    ...mapState('gerenciamentoServico', ['params']),
    ...mapState('auth', ['empresaAtual']),
    ehStatus() {
      return this.allowedStatus.includes(this.servicoID)
    },
  },

  methods: {
    ...mapActions('patio', ['getDashboardCountByParams']),
    ...mapMutations('gerenciamentoServico', ['UPDATE_PARAMS']),

    setServico(servicoID) {
      let params = this.params
      params['servico'] = servicoID
      params['proprietario'] = this.empresaAtual.public_id
      if (this.gate) {
        params['gate'] = this.gate
      }
      delete params.etapa
      this.UPDATE_PARAMS(params)
      this.$emit('search')
    },
    setEtapa(etapa) {
      let params = this.params

      params['servico'] = etapa.servico.id
      params['etapa'] = etapa.id
      params['proprietario'] = this.empresaAtual.public_id
      if (this.gate) {
        params['gate'] = this.gate
      }

      this.UPDATE_PARAMS(params)
      this.$emit('search')
    },

    getColor() {
      return this.colors[this.index] || this.defaultColor
    },

    async populateEtapa(etapa) {
      let params = { ...this.params }
      params['proprietario'] = this.empresaAtual.public_id
      if (this.gate) {
        params['gate'] = this.gate
      }
      if (params.servico) {
        delete params.servico
      }
      if (params.etapa) {
        delete params.etapa
      }
      const quantidadePorEtapa = await this.getDashboardCountByParams({
        ...params,
        servico: etapa.servico.id,
        etapa: etapa.id,
      })

      return {
        ...etapa,
        quantidadePorEtapa,
      }
    },

    async getContadorServico() {
      let params = { ...this.params }
      params['proprietario'] = this.empresaAtual.public_id
      if (this.gate) {
        params['gate'] = this.gate
      }
      if (params.servico) {
        delete params.servico
      }
      if (this.ehStatus) {
        this.quantidadePorServico = await this.getDashboardCountByParams({
          ...params,
          status: this.servicoID,
        })
      } else {
        this.quantidadePorServico = await this.getDashboardCountByParams({
          ...params,
          servico: this.servicoID,
        })
      }
    },

    async getContadoresEtapas() {
      let etapaComContadores = []
      this.etapas.forEach(async (etapa) => {
        const etapaComContador = await this.populateEtapa(etapa)
        etapaComContadores.push(etapaComContador)
      })
      this.etapaComContadores = etapaComContadores
    },

    async getContadores() {
      await this.getContadorServico()
      await this.getContadoresEtapas()
    },

    async getServicoLabel() {
      if (this.servicoID === 'AGENDADO') {
        this.nomeServico = 'Agendados'
      } else {
        const { descricao } = await PatioApi.getServicoByID(this.servicoID)
        this.nomeServico = descricao
      }
    },
  },

  async created() {
    await this.getServicoLabel()
  },
}
</script>

<style>
.dashboard__badge,
.dashboard__sheet {
  height: 100%;
  width: 100%;
}

.dashboard__badge .v-badge__badge {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  text-align: center;
  border-radius: 50%;
  height: 30px;
}
</style>
