export const props = {
  data: {
    type: Array,
    default: () => [],
  },
  dataTotal: {
    type: Number,
    required: true,
  },
  headers: {
    type: Array,
    required: true,
  },
  // options: {
  //   type: Object,
  //   default: () => {},
  // },
  search: {
    type: String,
    default: '',
  },
  pageSize: {
    typeof: Number,
    default: 15,
  },
  canDownload: {
    type: Boolean,
    default: false,
  },
  // tela: {
  //   type: String,
  //   required: true,
  // },
}
