<template>
  <v-container fluid class="container-comprovante text-center text-subtitle-2">
    <div class="text-center">
      <v-row>
        <v-col cols="2">
          <v-img
            :src="imgPath"
            height="100"
            width="400"
            contain
          />
        </v-col>
        <v-col cols="6">
          <h1>{{ agendamento.gate.business_name || defaultValue }}</h1>
        </v-col>
        <v-col cols="2">
        </v-col>
        <v-col cols="2">
          <v-img
            src="@/assets/images/icon/logo.png"
            height="100"
            width="400"
            contain
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h2>CERTIFICADO DE LIMPEZA DE CAMINHÕES</h2>
        </v-col>
      </v-row>

      <div class="v-data-table v-data-table--dense theme--light">
        <div class="v-data-table__wrapper">
          <table class="table">
            <tr>
              <th>Data</th>
              <td>{{ agendamento.agendado_em | toBrazilianDateTime }}</td>
              <th style="font-size: 30px">Laudo =</th>
              <td style="font-size: 30px">
                {{
                  agendamento.laudo_limpeza
                    ? agendamento.laudo_limpeza.numero
                    : 0
                }}
              </td>
            </tr>
            <tr>
              <th>Placa do Cavalo:</th>
              <td>{{ agendamento.veiculo.placa_cavalo || defaultValue }}</td>
              <td colspan="2">&nbsp;</td>
            </tr>
            <tr>
              <th>Placas da Carreta 1:</th>
              <td>{{ agendamento.veiculo.placa_carreta_1 || defaultValue }}</td>
              <td colspan="2">&nbsp;</td>
            </tr>
            <tr>
              <th>Placas da Carreta 2:</th>
              <td>{{ agendamento.veiculo.placa_carreta_2 || defaultValue }}</td>
              <td colspan="2">&nbsp;</td>
            </tr>
            <tr>
              <th>Motorista:</th>
              <td>{{ agendamento.motorista.user.name || defaultValue }}</td>
              <th>CPF</th>
              <td>{{ agendamento.motorista.user.cpf || defaultValue }}</td>
            </tr>
            <tr>
              <th>Transportador:</th>
              <td>{{ agendamento.transportadora.cnpj || defaultValue }}</td>
              <th>CNPJ</th>
              <td>
                {{ agendamento.transportadora.business_name || defaultValue }}
              </td>
            </tr>
            <tr>
              <th>Produto:</th>
              <td colspan="3">
                <strong
                  v-for="(item, index) in agendamento.pedidos_agendados"
                  :key="index"
                >
                  {{ item.pedido.produto.descricao + ' ' }}
                </strong>
              </td>
            </tr>
            <tr>
              <th>Quantidade:</th>
              <td>
                <strong
                  v-for="(item, index) in agendamento.pedidos_agendados"
                  :key="index"
                >
                  {{ (item.quantidade_agendada || '0') + ' ' }} T
                </strong>
              </td>
              <td colspan="2">&nbsp;</td>
            </tr>
            <tr>
              <th>Destinatario:</th>
              <td colspan="3">{{ agendamento.destinatario.trade_name }}</td>
            </tr>
          </table>
        </div>
      </div>

      <v-divider class="my-4"></v-divider>

      <v-row>
        <v-col>
          <v-data-table
            hide-default-footer
            disable-sort
            :headers="headersLaudo"
            :items="itensLaudo"
            sort-by="descricao"
            class="border"
            dense
          >
          <template v-slot:body="props">
            <tr v-for="(item, index) in props.items" :key="index">
              <td v-for="(header, index2) in headersLaudo" :key="index2" class="text-left table-td">
                <span v-if="index2==0">{{item.descricao}}</span>
                <v-icon v-if="item[header.value]=='X'" color="green" :title="item[header.value]">mdi-check</v-icon>
                <span class="text-start" v-if="index2==3">{{item.observacao}}</span>
              </td>
            </tr>
          </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <strong
            >Obs.: Certificamos que o caminhão passou pelo processo de limpeza e
            está apto a carregar qualquer tipo de carga nos armazéns em
            {{ agendamento.gate.cidade }}-{{ agendamento.gate.uf }}.</strong
          >
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <strong
            >Eu estou ciente da obrigatoriedade de utilização dos EPI?s (Sapato,
            colete refletivo e capacete de segurança)</strong
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p>______________________________________________________</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p>
            <span class="label">Motorista</span>
            <strong>{{
              agendamento.motorista.user.name || defaultValue
            }}</strong>
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <p>
            <strong
              >Aprovado (para aprovação todos os itens acima devem ter resposta
              ?Sim? )</strong
            >
          </p>
          <p>
            <strong>Reprovado</strong>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p>______________________________________________________</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p>
            <span class="label">Assinatura do Conferente</span>
          </p>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { toReal } from '@/mixins/convertion.js'
import { computed } from './_computed'
import { filters } from './_filters'
import { methods } from './_methods'
import { data } from './_data'

export default {
  name: 'Comprovante',
  inject: ['fecharComprovanteAgendamento'],
  mixins: [toReal],
  computed,
  filters,
  methods,
  data,
  props: {
    itensLaudo: {
      required: true,
    },
  },
}
</script>

<style>
.table-td {
  text-transform: uppercase; 
  padding: 0 16px; 
  font-size: 0.80rem; 
  color: rgba(0, 0, 0, 0.6);
}
</style>
