<template>
  <v-card>
    <v-card-title class="v-card">
      <span class="headline">Detalhes</span>
      <v-spacer />
      <v-btn type="button" color="primary" @click="$emit('close')"
        >Fechar</v-btn
      >
    </v-card-title>

    <v-card-text class="">
      <v-timeline class="px-2 py-8" :dense="$vuetify.breakpoint.smAndDown">
        <v-timeline-item
          v-for="(detalhe, index) of [...detalhes].reverse()"
          :key="index"
        >
          <v-card class="elevation-2 pa-4">
            <v-card-title class="text-h6">
              {{ detalhe.servico }} - {{ detalhe.etapa }}
            </v-card-title>
            <v-card-text>
              <div>
                <span>
                  Usuário:
                  <strong>{{ detalhe.executor.toUpperCase() }}</strong></span
                >
              </div>
              <div>
                <span>
                  Início:
                  <strong>{{
                    detalhe.iniciado_em | toLocaleDateTimeString
                  }}</strong>
                </span>
              </div>
              <div>
                <span>
                  Fim:
                  <strong>
                    {{ detalhe.finalizado_em | toLocaleDateTimeString }}
                  </strong>
                </span>
              </div>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
import { toLocaleDateTimeString } from '@/mixins/convertion'
export default {
  props: {
    detalhes: {
      type: Array,
      required: true,
    },
  },
  filters: { toLocaleDateTimeString },
}
</script>

<style>
.v-timeline-item:last-child {
  padding-bottom: 0 !important;
}
</style>
<style lang="scss" scoped >
.v-card {
  .v-card__text {
    padding-bottom: 0;
  }
}
</style>
