export const methods = {
  async showLog(item) {
    this.log = item
    this.show_log = true
  },
  getColor(item) {
    return item.status in this.status_color
      ? this.status_color[item.status]
      : this.status_color['0']
  },
  close() {
    this.show_log = false
  },
}
