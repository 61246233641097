<template>
  <div class="d-flex justify-center align-center">
    <div>
      <span class="text-caption">Quantidade: </span>
      <span class="text-caption font-weight-bold">
        {{ resumo.quantidade | toLocaleString }}</span
      >
      <span class="text-caption"> Cms</span>
    </div>
    <v-divider class="mx-4 my-1" vertical></v-divider>
    <div>
      <span class="text-caption">Peso: </span>
      <span class="text-caption font-weight-bold">
        {{ resumo.peso | toLocaleString }}</span
      >
      <span class="text-caption"> T</span>
    </div>
  </div>
</template>

<script>
import { BasicPermissions } from '@/utils/permissions'

export default {
  props: {
    resumo: {
      type: Object,
      default: () => {
        return { quantidade: 0, peso: 0 }
      },
    },
    permission: {
      type: String,
      required: false,
      default: null,
    },
  },
  filters: {
    toLocaleString(value) {
      if (!value) return 0
      return parseFloat(value).toLocaleString()
    },
  },
  data() {
    return {}
  },
  methods: {},
  computed: {
    canList() {
      if (!this.permission) return true
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
  },
}
</script>
