<template>
  <v-container class="container-comprovante text-center text-subtitle-2">
    <v-row>
      <v-col cols="12" class="d-flex justify-end">
        <span class="text-caption">{{ agendamento.public_id }}</span>
      </v-col>
    </v-row>
    <v-row id="actions">
      <v-col class="d-flex pa-8">
        <v-btn
          type="button"
          color="primary"
          @click="fecharComprovanteServicoAdicional"
          >Fechar</v-btn
        >
        <v-spacer />
        <v-btn color="secondary" @click="imprimirComprovante">Imprimir</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-comprovante">
        <p><strong>Comprovante de Serviço adicional</strong></p>
        <p class="mt-2 aviso">Ao descer do veículo, uso obrigatório de</p>
        <p class="mt-2 aviso">calço na roda, enquanto no pátio.</p>
      </v-col>
    </v-row>
    <v-row class="row-gate">
      <v-col class="col-comprovante">
        <strong>{{
          agendamento.gate.business_name ||
          agendamento.gate.tenant ||
          defaultValue
        }}</strong>
        <p>
          CNPJ: <strong>{{ agendamento.gate.cnpj || defaultValue }}</strong>
        </p>
      </v-col>
    </v-row>

    <v-row class="row-cliente">
      <v-col class="col-comprovante">
        <p>Cliente:</p>
        <p>
          <strong>{{
            agendamento.cliente.business_name || defaultValue
          }}</strong>
        </p>
      </v-col>
    </v-row>
    <v-row class="row-cliente">
      <v-col class="col-comprovante">
        <p>Usuário:</p>
        <p>
          <strong>{{ agendamento.agendado_por || defaultValue }}</strong>
        </p>
      </v-col>
    </v-row>
    <v-row class="row-veiculo">
      <v-col class="col-comprovante">
        <p>
          Cavalo:
          <strong>{{
            agendamento.veiculo.placa_cavalo || defaultValue
          }}</strong>
        </p>
        <p>
          Modalidade:
          <strong>{{
            agendamento.veiculo.modalidade.descricao || defaultValue
          }}</strong>
        </p>
        <p>
          Carroceria:
          <strong>{{
            agendamento.veiculo.carroceria.descricao || defaultValue
          }}</strong>
        </p>
      </v-col>
    </v-row>
    <v-row class="row-motorista">
      <v-col class="col-comprovante">
        <p>Motorista:</p>
        <p>
          <strong>{{ agendamento.motorista.user.name || defaultValue }}</strong>
        </p>
        <p>
          Cpf:
          <strong>{{ agendamento.motorista.user.cpf || defaultValue }}</strong>
        </p>
        <p>
          Celular:
          <strong>{{
            agendamento.motorista.user.celular || defaultValue
          }}</strong>
        </p>
      </v-col>
    </v-row>
    <v-row class="row-motorista">
      <v-col>
        <p>Entrada :</p>
        <p>
          <strong>{{
            agendamento.agendado_em || new Date() | toBrazilianDateTime
          }}</strong>
        </p>
        <p>Saida :</p>
        <p>
          <strong>{{
            agendamento.data_saida || new Date() | toBrazilianDateTime
          }}</strong>
        </p>
        <p>
          Horas em patio:
          <strong>{{ agendamento.total_horas || defaultValue }}</strong>
        </p>
        <p>
          Horas adicionais:
          <strong>{{
            agendamento.total_horas_adicionais || defaultValue
          }}</strong>
        </p>
        <p>
          Serviço:
          <strong>{{ agendamento.descricao || defaultValue }}</strong>
        </p>
        <p>
          Valor:
          <strong>{{ agendamento.valor_adicional || defaultValue }}</strong>
        </p>
      </v-col>
    </v-row>
    <v-row class="row-horarios">
      <v-col>
        <p>Data da cota para Carga/Descarga:</p>
        <p>
          <strong>{{ agendamento.data_cota | iso2br }}</strong>
        </p>
        <p>Data/Hora de chegada na Triagem:</p>
        <p>
          <strong>{{
            agendamento.triado_em || new Date() | toBrazilianDateTime
          }}</strong>
        </p>
        <div v-if="agendamento.cancelado_em">
          <p>Data/Hora do Cancelamento:</p>
          <p>
            <strong>{{
              agendamento.cancelado_em | toBrazilianDateTime
            }}</strong>
          </p>
          <p>
            Motivo do Cancelamento:
            <strong>{{ agendamento.motivo_cancelamento }}</strong>
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row class="row-senhas">
      <v-col
        class="col-comprovante my-3"
        v-for="_senha in agendamento.senhas"
        v-bind:key="_senha.public_id"
        cols="12"
      >
        <p><strong> Senha </strong></p>
        <p style="font-size: 30px">
          {{ _senha.objeto.descricao }} - {{ _senha.sequencia }}
        </p>
      </v-col>
    </v-row>
    <v-row class="row-senhas">
      <v-col>
        <div>
          <h3>Vaga</h3>
          <strong>{{
            agendamento.vaga != null ? agendamento.vaga.setor.descricao : '-'
          }}</strong>
        </div>
      </v-col>
    </v-row>
    <v-row class="row-cliente">
      <v-col class="col-comprovante">
        <div
          style="margin: 30px"
          v-for="(pedido, index) in agendamento.pedidos_agendados"
          :key="index"
        >
          <p>
            SubProduto:
            <strong>{{ pedido.pedido.subProduto.descricao }}</strong>
          </p>
          <p>
            Armazem:
            <strong>{{
              pedido.armazem != null
                ? pedido.armazem.descricao.toUpperCase()
                : '-'
            }}</strong>
          </p>
          <p>
            Quantidade:
            <strong>{{ converteKg(pedido.quantidade_agendada) }} KG</strong>
          </p>
          <p>
            N° NFE: <strong>{{ pedido.numero_nfe }}</strong>
          </p>
          <p>
            Embalagem: <strong>{{ pedido.pedido.embalagem.descricao }}</strong>
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-comprovante">
        <div
          style="margin: 30px"
          v-for="(servico, index) in agendamento.servicos_triados"
          :key="index"
        >
          <p>
            Servico: <strong>{{ servico.servico.descricao }}</strong>
          </p>
          <p>
            Forma Pagamento:
            <strong>{{ servico.forma_pagamento.descricao }}</strong>
          </p>
          <p>
            Valor: <strong>{{ 'R$ ' + getItemValor(servico) }}</strong>
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-end">
        <span class="text-caption">{{ agendamento.public_id }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { toReal } from '@/mixins/convertion.js'

import { data } from './_data'
import { computed } from './_computed'
import { filters } from './_filters'
import { methods } from './_methods'

export default {
  name: 'comprovanteServicoAdicional',
  inject: ['fecharComprovanteServicoAdicional'],
  mixins: [toReal],
  data,
  computed,
  filters,
  async created() {},
  methods,
}
</script>

<style>
.container-comprovante p {
  text-transform: uppercase;
  line-height: 5px;
}

.col-comprovante {
  padding: 1px;
}

.row-senhas strong {
  font-size: 20px;
  line-height: 20px;
}
strong {
  font-size: 15px;
  line-height: 15px;
}

p {
  font-size: 15px;
  line-height: 15px;
}
/* configuração para impressora térmica */
@media print {
  .v-application--wrap,
  .v-dialog button {
    display: none;
  }

  .v-dialog {
    display: block;
    height: 100vh;
    width: 100vw;
    margin: 0;
  }

  .v-card {
    height: 100%;
    width: 100%;
  }

  .v-data-table th,
  .v-data-table td {
    font-size: 10px !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .aviso {
    font-size: 10px;
  }

  @page {
    margin: 0;
  }
}
</style>
