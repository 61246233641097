var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"my-8"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[(_vm.canList)?_c('v-card',[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"d-flex justify-space-between align-center mx-8"},[_c('v-card-title',[_vm._v("Dashboard")]),_c('div',[(_vm.showSelectGate)?_c('v-autocomplete',{attrs:{"label":"Gate","prepend-icon":"mdi-domain","items":_vm.gates,"item-text":function (item) { return ((item.business_name) + " - " + (item.cnpj)); },"item-value":"public_id"},on:{"change":_vm.handleGateSelecionado},model:{value:(_vm.gateSelecionado),callback:function ($$v) {_vm.gateSelecionado=$$v},expression:"gateSelecionado"}}):_vm._e()],1),_c('div',[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","disabled":_vm.disableButtonFiltro},on:{"click":function () {
                    this$1.showDashboard = 1
                    _vm.toggleFilters()
                  }}},[_vm._v("Filtros")])],1)],1)]),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',{staticClass:"flex-nowrap overflow-auto",class:{
              'justify-center': Object.keys(_vm.dashboardFilters).length <= 6,
            }},_vm._l((Object.entries(
                _vm.dashboardFilters
              )),function(ref,index){
              var servicoID = ref[0];
              var etapas = ref[1];
return _c('v-col',{key:index,staticClass:"d-flex justify-center",attrs:{"cols":"2","md":"2"}},[_c('dashboard-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.tudoPopulado),expression:"tudoPopulado"}],ref:"dashboardItem",refInFor:true,staticClass:"dashboard__item",attrs:{"index":index,"servicoID":servicoID,"etapas":etapas,"limit":999,"gate":_vm.gateSelecionado},on:{"search":_vm.search}})],1)}),1),_c('v-overlay',{attrs:{"value":!_vm.tudoPopulado}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)],1)],1):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":500},model:{value:(_vm.showFilters),callback:function ($$v) {_vm.showFilters=$$v},expression:"showFilters"}},[_c('dashboard-filter',{on:{"save":_vm.handleFilter}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }