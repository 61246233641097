<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <v-tabs v-if="canList" v-model="tab">
      <v-tab href="#geral" @change="carregarConteudo('geral')">Geral</v-tab>
      <v-tabs-items v-model="tab">
        <v-tab-item value="geral">
          <geral
            :search="search"
            :headers="headers"
            :tela="permission"
            ref="geral"
            @loading="loading = true"
            @loaded="loading = false"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
    <v-tabs v-else-if="!canList">
      <span class="red--text"
        >Você não tem permissão para visualizar as informações desta tela</span
      >
    </v-tabs>
  </div>
</template>

<script>
import Geral from './geral/Componente.vue'
import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  props: {
    headers: {
      type: Array,
    },
    search: {
      type: String,
    },
  },
  components: {
    Geral,
  },
  data() {
    return {
      tab: 'geral',
      loading: false,
    }
  },
  methods: {
    async carregarConteudo(tab) {
      await this.$refs[tab].handleGetAgendamentos()
    },
    async handleGetAgendamentos() {
      await this.carregarConteudo(this.tab)
    },
  },
  computed: {
    permission() {
      return Sequences.GerenciamentoServico.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },
  async mounted() {
    if (this.canList) {
      await this.handleGetAgendamentos()
    }
  },
}
</script>
