export function data() {
  return {
    headersDocumentos: [
      { text: 'Descrição', value: 'description' },
      { text: 'Data', value: 'uploaded_on' },
      { text: 'De', value: 'from' },
      { text: '', value: 'actions' },
    ],
  }
}
